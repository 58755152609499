.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subtitle {
  margin-top: 24px;
  margin-bottom: 0;
  color: #444343;
  font-size: 22px;
  font-weight: 500;
}
