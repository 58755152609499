@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

.headerPage {
  font-family: "Roboto", sans-serif !important;
  font-weight: 300;
}

.md-form > input:focus {
  border-bottom: 2px solid #5c2826 !important;
  box-shadow: 0 0px 0 0 #5c2826 !important;
}

.btn-default {
  color: #fff;
  background-color: #5c2826 !important;
}

div.date-picker__date-display {
  background-color: #5c2826 !important;
}

.primary-text {
  color: #5c2826 !important;
  border-color: #5c2826 !important;
}

/* .btn-primary {
  color: #fff;
  background-color: #5c2826 !important;
} */

button.btn.Ripple-parent.btn-flat.px-3.py-2 {
  color: #5c2826 !important;
}

/* OVERRIDING TEMPLATE BUTTON STYLE */
.btn-primary {
  color: #fff;
  background-color: #5c2826 !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #813f3c;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: #5c2826 !important;
}
.btn-primary.dropdown-toggle {
  background-color: #5c2826 !important;
}
.btn-primary.dropdown-toggle:hover,
.btn-primary.dropdown-toggle:focus {
  background-color: #813f3c !important;
}
.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #ac4f4c !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-primary:not([disabled]):not(.disabled):active:focus,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.white-skin .btn-primary:focus,
.white-skin .btn-primary:active,
.white-skin .btn-primary.active {
  background-color: #ac4f4c;
}
.white-skin .btn-primary.dropdown-toggle {
  /* background-color: #5c2826 !important; */
}
.white-skin .btn-primary.dropdown-toggle:hover,
.white-skin .btn-primary.dropdown-toggle:focus {
  background-color: #813f3c !important;
}
.white-skin .btn-primary:not([disabled]):not(.disabled):active,
.white-skin .btn-primary:not([disabled]):not(.disabled).active,
.show > .white-skin .btn-primary.dropdown-toggle {
  background-color: #ac4f4c !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn-default {
  color: #fff;
  background-color: #5c2826 !important;
}
.btn-default:hover {
  color: #fff;
  background-color: #30cfc0;
}
.btn-default:focus,
.btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-default:focus,
.btn-default:active,
.btn-default.active {
  background-color: #853b39;
}
.btn-default.dropdown-toggle {
  background-color: #5c2826 !important;
}
.btn-default.dropdown-toggle:hover,
.btn-default.dropdown-toggle:focus {
  background-color: #853b39 !important;
}
.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  background-color: #853b39 !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-default:not([disabled]):not(.disabled):active:focus,
.btn-default:not([disabled]):not(.disabled).active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.picker__input--active {
  border-color: transparent !important;
  box-shadow: 0 0 0 0rem #ac4f4c7a !important;
}

/* OVERRIDING TEMPLATE BUTTON STYLE -- END*/

div.modal-header {
  background-color: #5c2826;
}

h4.modal-title {
  font-weight: 700;
  font-size: 22px !important;
  color: white;
  text-transform: uppercase;
  text-align: center !important;
}

.notifDetailPageHeader {
  font-weight: 500 !important;
  color: #853b39 !important;
  font-size: 30px;
  text-transform: uppercase;
}

.textAreaInput {
  border-radius: 10px !important;
}

.textAreaInput:focus {
  border-color: #813f3cc4 !important;
  box-shadow: 0 0 0 0.1rem #813f3c5e !important;
}

.link {
  color: #3a1d1b;
}
.link:hover {
  color: #64322f;
  cursor: pointer;
  text-decoration: underline;
}

.cardNavLink {
  padding: "1px 6px";
  font-size: "20px";
  font-weight: 500;
}
.cardNavLink:hover {
  text-decoration: underline;
}

.tabDetail {
  padding: 6px 24px;
  border-radius: 6px;
  width: 100%;
}
.tabDetail:hover {
  background-color: #7c3c39;
}

.classbook {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 100 !important;
  color: #fff;
}

.classbook-link {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 100 !important;
  color: #fff;
  cursor: pointer;
}

.classbook-link:hover,
.classbook-link:focus {
  color: #c9a4a2;
}

.classbook-link-container {
  width: 344px;
}

.error {
  color: red;
  margin-bottom: 14px;
  margin-top: -8px;
}
