.navItem {
  color: #3a3838 !important;
}

.navItem {
  font-size: 13px !important;
}

.navItem:focus {
  color: #5c2826 !important;
  font-size: 13px !important;
}

.collapsible-header {
  color: #3a3838 !important;
  font-size: 13px !important;
}

.collapsible-header.active {
  color: #5c2826 !important;
}

.side-nav .collapsible-body a {
  height: 36px;
  padding-left: 47px;
  line-height: 36px;
  background-color: transparent !important;
  color: #3a3838 !important;
}

.white-skin .side-nav .collapsible li a:not(.collapsible-header):hover,
.white-skin .side-nav .collapsible li a:not(.collapsible-header).active,
.white-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #5c2826 !important;
}

a.nav-link.Ripple-parent {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 43px !important;
}

.fixhome {
  margin-right: 5px !important;
}

a.nav-link.Ripple-parent.logout {
  margin-top: 8px !important;
  /* margin-left: -35px !important; */
  padding-left: 10px !important;
}

.dropdownLang:hover {
  background-color: #5c2826 !important;
}

.color1 {
  background-color: #5c2826 !important;
  color: #fff !important;
}

.lineHeight {
  line-height: 0px !important;
}
